import styles from "./Header.module.scss";
import logo from "../../assets/img/svg/logo.svg";
import { ButtonJoin } from "../ButtonJoin/ButtonJoin";
import menu from "../../assets/img/svg/menu.svg";
import cross from "../../assets/img/svg/cross.svg";
import { useState } from "react";
import cl from "classnames";
import { ButtonDiscord } from "../ButtonDiscord/ButtonDiscord";

interface IHeader {
	core?: boolean;
}

export const Header: React.FC<IHeader> = ({ core }) => {
	const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);

	return (
		<div
			className={cl(styles.wrapper, { [styles.mobileWrapper]: isOpenMobile })}
		>
			<div className={styles.content}>
				<div className={styles.menuBlock}>
					<img src={logo} alt="" />
					<div className={styles.menu}>
						<div>ABOUT</div>
						<div>GAMES</div>
						<div>PARTNERS</div>
						{/* <div>BLOG</div> */}
					</div>
				</div>
				<div className={styles.btns}>
					<ButtonJoin core={core} />
					<ButtonDiscord core={core} />
				</div>
				<img
					src={isOpenMobile ? cross : menu}
					alt=""
					className={styles.menuMobile}
					onClick={() => setIsOpenMobile(!isOpenMobile)}
				/>
			</div>
			{isOpenMobile && (
				<div className={styles.mobile}>
					<div className={styles.menuBlocksMobile}>
						<div>ABOUT</div>
						<div>GAMES</div>
						<div>PARTNERS</div>
						{/* <div>BLOG</div> */}
					</div>
					<div className={styles.btnsMobile}>
						<ButtonDiscord core={core} />
						<ButtonJoin core={core} />
					</div>
				</div>
			)}
		</div>
	);
};
