import styles from "./OurGames.module.scss";
import pixudiBackground from "src/modules/ourGames/assets/img/png/pixudiBackground.png";
import dypiansImage from "src/modules/ourGames/assets/img/png/dypiansImage.png";
import pixudiLogo from "src/modules/ourGames/assets/img/png/pixudiLogo.png";
import starsImage from "src/modules/ourGames/assets/img/png/starsImage.png";
import starsLogo from "src/modules/ourGames/assets/img/png/starsLogo.png";
import dypiansLogo from "src/modules/ourGames/assets/img/svg/dypiansLogo.svg";
import cl from "classnames";

export const OurGames = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>OUR GAMES</div>
			<div className={styles.cards}>
				<div
					className={cl(styles.card, styles.card1)}
					onClick={() => window.open("https://game.pixudi.com/")}
				>
					<img src={pixudiBackground} alt="" className={styles.background} />
					<div className={styles.cardContent}>
						<div className={styles.cardDescription}>
							MMORPG board game built on blockchain technology
						</div>
						<div className={styles.playNow}>PLAY NOW</div>
					</div>
					<div className={styles.logo}>
						<img src={pixudiLogo} alt="" className={styles.pixudiLogo} />
					</div>
				</div>
				<div
					className={cl(styles.card, styles.card2)}
					onClick={() => window.open("https://5tars.io/")}
				>
					<img src={starsImage} alt="" className={styles.background} />
					<div className={styles.cardContent}>
						<div className={styles.cardDescription}>
							A free-to-play Football Game where you stand a chance to win
							amazing prizes!
						</div>
						<div className={styles.playNow}>PLAY NOW</div>
					</div>
					<div className={styles.logo}>
						<img src={starsLogo} alt="" className={styles.starsLogo} />
					</div>
				</div>
				<div
					className={cl(styles.card, styles.card3)}
					onClick={() => window.open("https://www.worldofdypians.com/")}
				>
					<img src={dypiansImage} alt="" className={styles.background} />
					<div className={styles.cardContent}>
						<div className={styles.cardDescription}>
							Metaverse enabling users to explore and shape a world of infinite
							possibilities
						</div>
						<div className={styles.playNow}>PLAY NOW</div>
					</div>
					<div className={styles.logo}>
						<img src={dypiansLogo} alt="" className={styles.dypiansLogo} />
					</div>
				</div>
			</div>
			<div className={styles.titleGame}>HOW WE HELP GAME DEVELOPERS</div>
		</div>
	);
};
