import styles from "./ButtonJoin.module.scss";
import border from "src/modules/common/assets/img/png/border.png";
import borderCore from "src/modules/common/assets/img/png/borderCore.png";

interface IButtonJoin {
	core?: boolean;
}

export const ButtonJoin: React.FC<IButtonJoin> = ({ core = false }) => {
	return (
		<div
			className={styles.content}
			onClick={() =>
				core ? {} : window.open("https://forms.gle/xesswnXWcqhhZC4g8")
			}
		>
			<img src={core ? borderCore : border} alt="" className={styles.border} />
			<div className={styles.text}>Join the Guild</div>
		</div>
	);
};
