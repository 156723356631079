import { ButtonJoin } from "src/modules/common/ui/ButtonJoin/ButtonJoin";
import styles from "./LetsPlay.module.scss";
import cave from "src/modules/letsPlay/assets/img/png/cave.png";
import { ButtonDiscord } from "src/modules/common/ui/ButtonDiscord/ButtonDiscord";
import cl from "classnames";

interface ILetsPlay {
	core?: boolean;
}

export const LetsPlay: React.FC<ILetsPlay> = ({ core = false }) => {
	return (
		<div className={styles.wrapper}>
			<div className={cl(styles.content, styles.contentCore)}>
				<div className={styles.leftBlock}>
					<div className={styles.title}>LET’S PLAY!</div>
					<div className={styles.description}>
						Join Skuild to shape the future of Web3 gaming together
					</div>
					<div className={styles.btns}>
						<ButtonJoin core={core} />
						<ButtonDiscord core={core} />{" "}
					</div>
				</div>
				{!core && <img src={cave} alt="" className={styles.cave} />}
			</div>
		</div>
	);
};
