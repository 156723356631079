import styles from "./Card.module.scss";
import cl from "classnames";
import arrow from "src/modules/common/assets/img/svg/arrow.svg";

interface ICard {
	title: string;
	description: string;
	image: string;
	className?: string;
	classNameImage?: string;
	onClick?: Function;
}

export const Card: React.FC<ICard> = ({
	title,
	description,
	image,
	className,
	classNameImage,
}) => {
	const onClick = () => {
		switch (title) {
			case "NO GAS FEES":
				window.open("https://skale.space/");
		}
	};

	return (
		<div className={cl(styles.card, className)}>
			<div className={styles.cardTitle}>{title}</div>
			<div className={styles.cardDescription}>{description}</div>
			<div
				className={styles.arrow}
				onClick={onClick}
			>
				<div>Learn More</div>
				<img src={arrow} alt="" />
			</div>
			<img src={image} alt="" className={classNameImage} />
		</div>
	);
};
