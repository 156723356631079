import styles from "./GamingGuild.module.scss";
import { ButtonJoin } from "src/modules/common/ui/ButtonJoin/ButtonJoin";

interface IGamingGuild {
	cards: JSX.Element[];
	core?: boolean;
}

export const GamingGuild: React.FC<IGamingGuild> = ({
	cards,
	core = false,
}) => {
	return (
		<div className={styles.content} id={"gamingGuild"}>
			<div className={styles.title}>JOIN THE ULTIMATE GAMING GUILD</div>
			<div className={styles.cards}>
				{cards.map((item, index) => {
					return item;
				})}
			</div>
			<ButtonJoin core={core} />
		</div>
	);
};
