import { Route, Routes } from "react-router-dom";
import { MainPage } from "src/pages/MainPage/MainPage";

export function AppRoutes() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<MainPage />
					</>
				}
			/>
		</Routes>
	);
}
