import React from "react";
import "./App.css";
import { MainPage } from "../pages/MainPage/MainPage";
import { AppRoutes } from "./routes/AppRoutes";
import { HashRouter } from "react-router-dom";

function App() {
	return (
		<div className="App">
			<HashRouter>
				<AppRoutes />
			</HashRouter>
		</div>
	);
}

export default App;
