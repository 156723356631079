import { GamingEmpire } from "src/modules/gamingEmpire/ui/GamingEmpire/GamingEmpire";
import { Header } from "../../modules/common/ui/Header/Header";
import styles from "./MainPage.module.scss";
import { GamingGuild } from "src/modules/gamingGuild/ui/GamingGuild/GamingGuild";
import { OurGames } from "src/modules/ourGames/ui/OurGames/OurGames";
import { GameDevelopers } from "src/modules/gameDevelopers/ui/GameDevelopers/GameDevelopers";
import { LetsPlay } from "src/modules/letsPlay/ui/LetsPlay/LetsPlay";
import { Footer } from "src/modules/common/ui/Footer/Footer";
import { Card } from "src/modules/gamingGuild/ui/Card/Card";
import torch from "src/modules/gamingGuild/assets/img/png/torch.png";
import map from "src/modules/gamingGuild/assets/img/png/map.png";
import pick from "src/modules/gamingGuild/assets/img/png/pick.png";

export const MainPage = () => {
	const cards = [
		<Card
			title={"NO GAS FEES"}
			description={
				"Enhance your gaming experience with free transactions and endless scalability of SKALE blockchain"
			}
			image={torch}
			className={styles.card1}
			classNameImage={styles.torch}
		/>,
		<Card
			title={"OUR MISSION"}
			description={
				"We aim to build a strong community of game creators and gamers and drive the use of blockchain technology in gaming."
			}
			image={map}
			className={styles.card2}
			classNameImage={styles.map}
		/>,
		<Card
			title={"WHAT WE DO"}
			description={
				"Skuild was created to help developers on SKALE blockchain create, market and promote their games"
			}
			image={pick}
			className={styles.card3}
			classNameImage={styles.pick}
		/>,
	];

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<Header />
				<GamingEmpire />
			</div>
			<GamingGuild cards={cards} />
			<div className={styles.bottom}>
				<OurGames />
			</div>
			<GameDevelopers />
			<LetsPlay />
			<Footer />
		</div>
	);
};
